import { useState } from 'react';
import './App.css';
import 'victormono';

function App() {
  const [ href, setHref ] = useState();
  const [ shouldHandleClick, setShouldHandleClick ] = useState(true);

  const handleEmail = () => {
    if (shouldHandleClick) {
      setHref(decode('znvygb:vasb@ovgrpehapu.pbz'));
      setShouldHandleClick(false);
    }
  };

  const decode = a => a.replace(/[a-zA-Z]/g, c =>
    String.fromCharCode((c <= 'Z' ? 90 : 122) >= (c = c.charCodeAt(0) + 13)
                               ? c : c - 26));
  return (
    <div className="App">
      <header className="App-header">
        <h1>Bitecrunch</h1>
        <p>Helping The Met Office, Open University, EDF Energy, Friends Life and more engineer desktop and mobile web apps since 2010</p>
        <a href={href} onClick={handleEmail}>email us</a>
      </header>
    </div>
  );
}

export default App;
